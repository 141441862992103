@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    body{
        font-family: 'Nunito', sans-serif;
    }
}
